import React from "react";

class Meme extends React.Component {
    render() {
        return (
          <div className={`ui ${this.props.color} card`}>
              <div className='image'>
                <img src={this.props.mediaLink} />
              </div>
              <div className='extra content'>
                <span className='right floated'>
                  +10&nbsp;
                  <a>
                    <i className='thumbs up outline green icon'></i>
                  </a>
                </span>
                <span>
                    <i className='thumbs down outline red icon'></i>
                  &nbsp;-5
                </span>
              </div>
          </div>
        )
    }
}

export default Meme;

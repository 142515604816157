// src/components/NavBar.js

import React from "react";
import { useAuth0 } from "../react-auth0-spa";
import { Link } from "react-router-dom";

const NavBar = () => {
  const { isAuthenticated, loginWithPopup, logout } = useAuth0();

  return (
    <div>
      {!isAuthenticated && (
        <button onClick={() => loginWithPopup({response_type: "token id_token"})}>Log in</button>
      )}

      {isAuthenticated && <button onClick={() => logout()}>Log out</button>}
      {/* NEW - add a link to the home and profile pages */}
       {isAuthenticated && (
         <span>
           <Link to="/">Home</Link>&nbsp;
           <Link to="/profile">Profile</Link>
         </span>
       )}
    </div>
  );
};

export default NavBar;

import React from "react";
import NavBar from "./components/NavBar";
import { useAuth0 } from "./react-auth0-spa";
import logo from './logo.svg';
import './App.css';
import { Router, Route, Switch } from "react-router-dom";
import Profile from "./components/Profile";
import Home from "./components/Home";
import history from "./utils/history";

function App() {
  const { loading } = useAuth0();
  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <div className="App">
     {/* Don't forget to include the history module */}
     <Router history={history}>
       <header>
         <NavBar />
       </header>
       <Switch>
         <Route path="/" exact component={Home} />
         <Route path="/profile" component={Profile} />
       </Switch>
     </Router>
   </div>
  );
}

export default App;

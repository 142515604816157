import React, { Fragment } from "react";
import MemeList from "./MemeList.js";
import { useAuth0 } from "../react-auth0-spa";

const Home = () => {
    const { loading, user } = useAuth0();

    if (loading || !user) {
      return <div>You must be logged in to view this page</div>;
    }
    return (
      <Fragment>
        <MemeList />
      </Fragment>
    );
}

export default Home;

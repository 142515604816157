import React from "react";
import Meme from "./Meme.js";
import { useAuth0 } from "../react-auth0-spa";
import createAuth0Client from '@auth0/auth0-spa-js';
import config from "../auth_config.json";

const API_ENDPOINT = "https://api-staging.memegenerator.fun";
class MemeList extends React.Component {
  constructor(props) {
    super(props);
    this.state={memes: [], loading: false};
  }
  componentDidMount() {
    this.loadMemesFromTheServer();
  }

  loadMemesFromTheServer() {
    this.setState({loading: true});
    this.getMemes((memes) => (
        this.setState({memes: memes, loading: false})
      )
    );
  }

  getMemes(success) {
    const { getTokenSilently } = useAuth0();
    return createAuth0Client({
      domain: config.domain,
      client_id: config.client_id
    }).then(auth0 => auth0.getTokenSilently({
      audience: config.audience,
      scope: config.scope
    })).then(accessToken =>
        fetch(`${API_ENDPOINT}/v1/meme`, {
         headers: {
           Accept: 'application/json',
           Authorization: `Bearer ${accessToken}`
         },
       })
     ).then(this.checkStatus)
         .then(this.parseJSON)
         .then(success);
 }

  render() {
    if (this.state.loading) {
      return (
        <div className='ui container'>
            <div className='ui top attached loading segment'>
             <p></p>
             <p></p>
            </div>
        </div>)
    } else {
      const memes = this.state.memes;
      const colors = ["red", "orange", "yellow", "green", "blue", "violet", "purple"];
      const memeComponents = memes.map((meme, index) => (
          <Meme
            key = {'meme-' + meme.id}
            id={meme.id}
            topText={meme.topText}
            bottomText={meme.bottomText}
            mediaLink={meme.mediaLink}
            color = {colors[index % colors.length]}
          />
      ));
      return (
        <div className='ui container'>
          <div className='ui top attached segment'>
            <div className='ui cards'>
              {memeComponents}
            </div>
          </div>
        </div>
      );
    }
  }

  checkStatus(response) {
   if (response.status >= 200 && response.status < 300) {
     return response;
   } else {
     const error = new Error(`HTTP Error ${response.statusText}`);
     error.status = response.statusText;
     error.response = response;
     console.log(error);
     throw error;
   }
 }

 parseJSON(response) {
   return response.json();
 }

 getMemes(success) {
   createAuth0Client({
     domain: 'memegenerator.auth0.com',
     client_id: 'L5nFo74z5cyGre3vy3WznZHg2BW6n7uX'
   }).then(auth0 => auth0.getTokenSilently({
     audience: "https://api-staging.memegenerator.fun",
     scope: "openid profile email"
   })).then(accessToken =>
       fetch("https://api-staging.memegenerator.fun/v1/meme", {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`
        },
      })
    ).then(this.checkStatus)
        .then(this.parseJSON)
        .then(success);
  }

}

export default MemeList;
